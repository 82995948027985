<template>
  <div id="map-wrapper">
    <div id="map-container"></div>
    <div class="gradient-overlay"></div>
    <div class="text-overlay d-flex justify-content-center align-items-center">
      <div class="container" style="width: 100%;">

        <div class="row justify-content-center" v-if="this.loading">
          <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div id="hello" class="center" v-if="!this.distance && !this.loading">
          <h1>✨ Hello! </h1>
          <p>With this tool you will be able to calculate the ideal ping between two cities. Keep in mind that the
            actual pings may vary significantly.</p>
        </div>
        <div id="result" class="center" v-if="this.distance && !this.loading">
          <h1>
            ⚡ Best possible ping is <span class="green"> {{ this.ping }}ms </span>
          </h1>
          <p>calculated using the speed of light and average refractive index of optical fiber at a distance of
            <span v-if="miles">
              {{ convertToMiles(this.distance) }} mi
            </span>
            <span v-else>
              {{ this.distance }} km
            </span>
          </p>
        </div>

        <div class="row" v-if="!this.loading">
          <div class="col">
            Source city:
            <select class="form-select form-select-lg" v-model="cityOne">
              <option value="null" disabled selected>Select source city</option>
              <option v-for="city in cities" :key="city" :value="city[0]">{{ city[0] }} {{ country2flag(city[3]) }}
              </option>
            </select>
          </div>
          <div class="col">
            Destination city:
            <select class="form-select form-select-lg" v-model="cityTwo">
              <option value="null" disabled selected>Select destination city</option>
              <option v-for="city in cities" :key="city" :value="city[0]">{{ city[0] }} {{ country2flag(city[3]) }}
              </option>
            </select>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      cities: [],
      cityOne: null,
      cityTwo: null,
      cityOneCoords: [0, 0],
      cityTwoCoords: [0, 0],
      distance: 0,
      ping: 0,
      map: null,
      miles: false,
      loading: true
    }
  },
  watch: {
    cityOne() {
      console.log(this.cityOne, this.cityTwo)
      this.cities.forEach(element => {
        if (element[0] == this.cityOne) {
          this.cityOneCoords = [element[1], element[2]]
          this.calc();
        }
      });
    },
    cityTwo() {
      this.cities.forEach(element => {
        if (element[0] == this.cityTwo) {
          this.cityTwoCoords = [element[1], element[2]]
          this.calc();
        }
      });
    }
  },
  mounted() {
    this.loadCities()
    this.initMap()
  },
  methods: {
    calc() {
      if (this.cityOne && this.cityTwo) {
        this.calculateDistance();
        this.calculatePing();
        this.drawCurvedLine(this.cityOneCoords[0], this.cityOneCoords[1], this.cityTwoCoords[0], this.cityTwoCoords[1])
        document.getElementById('map-container').hidden = false
      }
    },
    async loadCities() {
      // load cities from cities.json
      const response = await fetch('/cities2.json')
      const json = await response.json()
      this.cities = json
      this.loading = false
    },
    haversine(lat1, lon1, lat2, lon2) {
      const R = 6371;
      const toRad = (value) => (value * Math.PI) / 180;

      const dLat = toRad(lat2 - lat1);
      const dLon = toRad(lon2 - lon1);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = R * c;
      return distance;
    },
    country2flag(countryCode) {
      return countryCode
        .toUpperCase()
        .split('')
        .map(char => String.fromCodePoint(char.charCodeAt(0) + 0x1F1A5))
        .join('');
    },
    calculateDistance() {
      this.distance = this.haversine(this.cityOneCoords[0], this.cityOneCoords[1], this.cityTwoCoords[0], this.cityTwoCoords[1])
      this.distance = (this.distance + (this.distance * 0.1)).toFixed(0)
    },
    convertToMiles(km) {
      return (km * 0.621371).toFixed(0)
    },
    calculatePing() {
      this.ping = this.distance * 0.0048 * 2
      console.log((this.distance / 200000) * 1000 * 2)
      this.ping = this.ping.toFixed(2)
    },
    async setupMapKitJS() {
      if (!window.mapkit || window.mapkit.loadedLibraries.length === 0) {
        await new Promise(resolve => { window.initMapKit = resolve });
        delete window.initMapKit;
      }
    },
    async initMap() {
      await this.setupMapKitJS();
      let mapkit = window.mapkit;
      const cupertino = new mapkit.CoordinateRegion(
        new mapkit.Coordinate(37.3316850890998, -122.030067374026),
        new mapkit.CoordinateSpan(0.167647972, 0.354985255)
      );

      // Create a map in the element whose ID is "map-container".
      window.mapInstance = new mapkit.Map("map-container");
      window.mapInstance.region = cupertino;
      window.mapInstance.mapType = mapkit.Map.MapTypes.Satellite;
      window.mapInstance.showsCompass = mapkit.FeatureVisibility.Hidden;
      // Hide the map type and the zoom controls.
      window.mapInstance.showsMapTypeControl = false;
      window.mapInstance.showsZoomControl = false;

    },
    setCenter(latitude, longitude) {
      console.log(latitude, longitude)
      const mapkit = window.mapkit;
      const newCenter = new mapkit.Coordinate(latitude, longitude);

      // Access the map instance from the window object.
      window.mapInstance.setCenterAnimated(newCenter);
    },
    drawCurvedLine(startLat, startLng, endLat, endLng) {
      const mapkit = window.mapkit;

      // remove existing line
      if (window.mapInstance.overlays.length > 0) {
        window.mapInstance.removeOverlay(window.mapInstance.overlays[0]);
      }

      // Создаем начальную и конечную координаты
      const startPoint = new mapkit.Coordinate(startLat, startLng);
      const endPoint = new mapkit.Coordinate(endLat, endLng);

      // Создаем массив координат для линии
      const coordinates = [startPoint, endPoint];

      // Создаем полилинию
      const line = new mapkit.PolylineOverlay(coordinates, {
        style: new mapkit.Style({
          strokeColor: '#007aff',  // Синий цвет
          lineWidth: 3,            // Толщина линии
          lineDash: []              // Сплошная линия
        })
      });

      // Добавляем линию на карту
      window.mapInstance.addOverlay(line);

      const minLat = Math.min(startLat, endLat) - 10;
      const maxLat = Math.max(startLat, endLat) + 10;
      const minLng = Math.min(startLng, endLng) - 10;
      const maxLng = Math.max(startLng, endLng) + 10;

      console.log(minLat, maxLat, minLng, maxLng)

      var region = new mapkit.BoundingRegion(maxLat, maxLng, minLat, minLng);
      window.mapInstance.setRegionAnimated(region.toCoordinateRegion(), true);
    }
  }
}
</script>

<style>
html,
body {
  background: #202124;
  color: #fff;
}

.form-select {
  border-radius: 16px;
}

.nav {
  background: #424242;
  border-radius: 16px;
}

.nav-pills>a {
  color: #fff !important;
}

.nav-pills>a.active {
  background: #717070 !important;
  border-radius: 16px;
}

#app {
  font-family: "Ubuntu Sans Mono", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-optical-sizing: auto;
  -moz-osx-font-smoothing: grayscale;
}

.green {
  color: #3bd671;
}

.center {
  text-align: center;
}

/* Контейнер, управляющий всей конструкцией */
#map-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  border-radius: 16px;
}

/* Контейнер для карты */
#map-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
}

/* Градиент поверх карты */
.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #20212496;
  z-index: 1;
}

/* Текст поверх карты */
.text-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  text-align: center;
}
</style>
